<template>
	<b-card-group deck>
		<b-card>
			<b-row>
				<b-col>
					<h4 class="mb-4">
						{{ translate('update_address') }}
					</h4>
				</b-col>
			</b-row>
			<hr>
			<b-row>
				<b-col>
					<address-country-form
						v-if="!loading"
						:address="ADDRESSES.shipping"
						:user-country="SHOW_USER_COUNTRY"
						:form-import="form"
						:country-code-import="countryCode"
						:display-override-toggle="showOverrideValidation"
						:errors-import="errors.errors"
						:loading="loading"
						:current-personal-document-front-file="currentPersonalDocumentFrontFile"
						:current-personal-document-back-file="currentPersonalDocumentBackFile"
						@on-submit="updateAddress" />
					<is-loading
						v-else
						:loading-label="translate('loading')"
						:loading="loading" />
				</b-col>
			</b-row>
		</b-card>
	</b-card-group>
</template>
<script>

import AddressCountryForm from '@/components/AddressCountryForm';
import { Profile, Validations, Addresses } from '@/translations';
import AddressBook from '@/util/AddressBook';
import { SHOW_USER_COUNTRY } from '@/settings/Country';
import { SYSTEM_ALERT, UNPROCESSABLE } from '@/settings/Errors';
import { ADDRESS_VERIFICATION_COUNTRIES as addressVerificationCountries } from '@/settings/Shipping';
import EventBus from '@/util/eventBus';
import { ADDRESSES } from '@/settings/AddressBook';

export default {
	name: 'UpdateAddress',
	messages: [Profile, Validations, Addresses],
	components: { AddressCountryForm },
	data() {
		return {
			form: {
				first_name: '',
				last_name: '',
				address: '',
				address2: '',
				address3: '',
				address4: '',
				city: '',
				postcode: '',
				country_code: '',
				state: '',
				region_id: '',
				personal_document_number: '',
				personal_document_front_file: null,
				personal_document_back_file: null,
				override_validation: false,
			},
			addressBook: new AddressBook(),
			getSuggestion: new AddressBook(),
			addressDocument: new AddressBook(),
			alert: new this.$Alert(),
			SHOW_USER_COUNTRY,
			showOverrideValidation: false,
			addressVerificationCountries,
			countryCode: '',
			currentPersonalDocumentFrontFile: null,
			currentPersonalDocumentBackFile: null,
			loading: true,
			ADDRESSES,
		};
	},
	computed: {
		errors() {
			return this.addressBook.data.errors;
		},
	},
	mounted() {
		this.addressBook.getAddress(this.$user.details().id, this.$route.params.address_id).then((response) => {
			this.form.first_name = response.attributes.first_name;
			this.form.last_name = response.attributes.last_name;
			this.form.address = response.attributes.address;
			this.form.address2 = response.attributes.address2;
			this.form.address3 = response.attributes.address3;
			this.form.address4 = response.attributes.address4;
			this.form.city = response.attributes.city;
			this.form.postcode = response.attributes.postcode;
			this.form.gift_address = response.attributes.is_gift_address;
			this.form.country_code = response.attributes.country.code;
			this.form.region_id = response.attributes.region.id;
			this.form.state_code = response.attributes.region.code;
			this.form.personal_document_number = response.attributes.personal_document_number;
			this.countryCode = response.attributes.country.code;
			this.loading = false;
		});

		this.addressDocument.getAddressDocument(this.$user.details().id, this.$route.params.address_id, 'front').then(() => {
			const { response } = this.addressDocument.data;
			const url = window.URL.createObjectURL(response.data);
			this.currentPersonalDocumentFrontFile = url;
		});

		this.addressDocument.getAddressDocument(this.$user.details().id, this.$route.params.address_id, 'back').then(() => {
			const { response } = this.addressDocument.data;
			const url = window.URL.createObjectURL(response.data);
			this.currentPersonalDocumentBackFile = url;
		});
	},
	methods: {
		updateAddress(form) {
			this.alert.loading(this.translate('loading_title'), this.translate('loading_text'));

			const payload = { ...form };
			if (!this.showOverrideValidation) {
				payload.override_validation = undefined;
			}

			this.addressBook.updateAddress(this.$user.details().id, this.$route.params.address_id, this.objectToFormData(payload)).then(() => {
				this.alert.toast('success', this.translate('address_updated'), { timer: 6000 });
				this.$router.push({ name: 'AddressBook' });
			}).catch(() => {
				if (UNPROCESSABLE.includes(this.errors.status)) {
					Object.keys(this.errors.errors).forEach((errorKey) => {
						if (errorKey === 'suggestion') {
							this.getSuggestion.getSuggestion(form).then((suggestionResponse) => {
								let address = '';
								Object.keys(suggestionResponse.response.address).forEach((item, index) => {
									if (suggestionResponse.response.address[item] !== '' && item !== 'region_id') {
										address += `${suggestionResponse.response.address[item]}`;
										if (index < (Object.keys(suggestionResponse.response.address).length) - 2) {
											address += ', ';
										}
									}
								});
								const trans = {
									title: this.translate('invalid_address'),
									text: this.translate('suggestion_address', { address }),
								};
								const options = {
									config: {
										icon: 'error',
										showCancelButton: true,
										confirmButtonText: this.translate('accept_sugestion'),
										cancelButtonText: this.translate('manual_change'),
										reverseButtons: true,
										allowOutsideClick: false,
										allowEscapeKey: false,
									},
								};
								this.alert.confirmation(trans.title, trans.text, options, true).then(() => {
									Object.keys(suggestionResponse.response.address).forEach((suggestionItem) => {
										Object.keys(this.form).forEach((formItem) => {
											if (formItem === suggestionItem) {
												this.form[formItem] = suggestionResponse.response.address[suggestionItem];
											}
										});
									});
									this.updateAddress(this.form);
								}).catch(() => {});
							}).catch(() => {});
						} else if (errorKey === 'address' && !!this.form.address.trim().length && this.addressVerificationCountries.includes(this.form.country_code)) {
							this.showOverrideValidation = true;
						}
					});
				}
				if (SYSTEM_ALERT.includes(this.errors.status) || this.errors.status === undefined) {
					this.alert.toast('error', this.translate('default_error_message'), { timer: 6000 });
				} else {
					this.alert.close();
				}
			}).finally(() => {
				EventBus.$emit('updateAccountSteps');
			});
		},
	},
};
</script>
